/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W 2023 roku zakończono prace nad projektem pn. „Odtworzenie pierwotnej pojemności zbiornika przystopniowego w Rzeszowie na rzece Wisłok”. Zamawiający w ramach środków Programu Operacyjnego Infrastruktura i Środowisko 2014-2020 zrealizował  projekt, którego efektem końcowym jest przywrócenie pojemności retencyjnej zbiornika wodnego poprzez wydobycie zalegających w nim osadów dennych wraz z ich zagospodarowaniem w zgodności z przepisami ustawy o odpadach z 14.12.2012 roku."), "\n", React.createElement(_components.p, null, "Prace przy odmulaniu zbiornika prowadzone były w dwóch etapach i trwały w sumie trzy lata. Drugi etap był trudniejszy, bo prace prowadzone były na terenie objętym ochroną Natura 2000. Na tym obszarze występowały m.in. chronione lęgowiska ptaków."), "\n", React.createElement(_components.p, null, "W ramach projektu zorganizowano potężne zaplecze techniczne. Kilka lat na zbiorniku Wisłok pracowały refulery, koparki, pogłębiarki, taśmociągi."), "\n", React.createElement(_components.p, null, "Dzięki przeprowadzonym pracom pojemność zbiornika wynosi prawie 1,2 mln m sześciennych, to niemal dwa razy więcej niż przed pracami."), "\n", React.createElement(_components.p, null, "Przeprowadzone prace poprawiły bezpieczeństwo przeciwpowodziowe mieszkańców Rzeszowa oraz pozwoliły na dostęp do ogromnych zasobów wody pitnej."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
